@tailwind base;
@tailwind components;
@tailwind utilities;

/* these classes for for react css transitions */

.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all 500ms ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all 500ms ease;
}

.menu-secondary-enter {
  position: absolute;
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all 500ms ease;
}
.menu-secondary-exit {
  position: absolute;
}
.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all 500ms ease;
}
